const ENUMS = {
  XPERDRIVE_API: {
    GetFolders: "/api/File/GetFolders",
    FileUpload: "/api/File/FileUpload",
    GetFolderFiles: "/api/File/GetFolderFiles",
    FileFolderSearch: "/api/File/FileFolderSearch",
    FileSearch: "/api/File/FileSearch",
    FileDownload: "/api/File/FileDownload",
    GetUsers: "/api/File/GetUsers",
    FileShare: "/api/File/FileShare",
    PublicShare: "/api/File/PublicShare",
    GetFiles: "/api/File/GetFiles",
    IsRegisteredUser: "/api/File/IsRegisteredUser",
    Register: "/api/File/Register",
    DeleteFile: "/api/File/DeleteFile",
    GetSharedFiles: "/api/File/GetSharedFiles",
    GetSharedFilesAndFolder: "/api/File/GetSharedFilesAndFolder",
    GetFoldersAndFiles: "/api/File/GetFoldersAndFiles",
    StopFileSharing: "/api/File/StopFileSharing",
    GetFileSharedUsers: "/api/File/GetFileSharedUsers",
    CreateFolder: "/api/File/CreateFolder",
    DeleteFolder: "/api/File/DeleteFolder",
    StopSharingFileWithMe: "/api/File/StopSharingFileWithMe",
    FolderShare: "/api/File/FolderShare",
    StopFolderSharing: "/api/File/StopFolderSharing",
    MoveFiles: "/api/File/MoveFiles",
    FileUploadAnonymous: "/api/File/FileUploadAnonymous",
    FileDownloadAnonymous: "/api/File/FileDownloadAnonymous",
    ImageUploadAnonymous: "/api/File/ImageUploadAnonymous",
    SearchUser: "/api/File/SearchUser",
    FolderSharePermission: "/api/File/FolderSharePermission",
    CheckUser: "/api/File/CheckUser",
    GetMyStorageUsage: "/api/File/GetMyStorageUsage",
    RenameFolderAndFile: "/api/File/RenameFolderAndFile",
    PublicAvatarUpload: "/api/File/PublicAvatarUpload",
  },
};

export default ENUMS;
