import axios from "axios";
import api from "./api";
import fileEvents from "./events";
import { saveByteArray } from "../utils/misc";

export default {
  fileEvents,
  isCancel(err) {
    return axios.isCancel(err);
  },
  emit(eventType, callbackFn) {
    fileEvents.emit(eventType, callbackFn);
  },
  on(eventType, callbackFn) {
    fileEvents.on(eventType, callbackFn);
  },
  FileUpload(params, payload, onUploadProgress, cancelOptions) {
    return api.fileUpload(params, payload, onUploadProgress, cancelOptions);
  },
  FileDownload(payload, onDownloadProgress, onCancelTokenReady) {
    const { source, promise } = api.fileDownload(payload, onDownloadProgress);

    if (onCancelTokenReady) {
      onCancelTokenReady(source);
    }

    return promise.then((response) => {
      const { fileName } = payload;
      saveByteArray(fileName, response.data, "application/octet-stream");
    });
  },
  FileShare(payload) {
    fileEvents.emit("FileShared", payload);
    return api.fileShare(payload);
  },
  FileFolderSearch(payload) {
    return api.fileFolderSearch(payload);
  },
  PublicFileShare(payload) {
    fileEvents.emit("PublicFileShared", payload);
    return api.publicFileShare(payload);
  },
  FileSearch(payload) {
    return api.fileSearch(payload);
  },
  CreateFolder(payload) {
    return api.createFolder(payload);
  },
  Register(payload) {
    return api.register(payload);
  },
  GetFiles(query) {
    return api.getFiles(query);
  },
  StopFileSharing(payload) {
    return api.stopFileSharing(payload);
  },
  DeleteFile(payload) {
    return api.deleteFile(payload);
  },
  FolderShare(payload) {
    return api.folderShare(payload);
  },
  FoderUpdatePermissions(payload) {
    return api.folderUpdatePermissions(payload);
  },
  DeleteFromSharedUsers(folderId, payload) {
    return api.deleteFromSharedUsers(folderId, payload);
  },
  GetFolderFiles(payload) {
    return api.getFolderFiles(payload);
  },
  GetSharedFiles() {
    return api.getSharedFiles();
  },
  GetSharedFilesAndFolders(payload) {
    return api.getSharedFilesAndFolders(payload);
  },
  GetFoldersAndFiles(payload) {
    return api.getFoldersAndFiles(payload);
  },
  DeleteFolder(payload) {
    return api.deleteFolder(payload);
  },
  StopFolderSharing(payload) {
    return api.stopFolderSharing(payload);
  },
  GetFolders(payload) {
    return api.getFolders(payload);
  },
  MoveFiles(payload) {
    return api.moveFiles(payload);
  },
  GetUsers() {
    return api.getUsers();
  },
  IsRegisteredUser() {
    return api.isRegisteredUser();
  },
  GetFileSharedUsers(payload) {
    return api.getFileSharedUsers(payload);
  },
  StopFileSharingWithMe(payload) {
    return api.stopFileSharingFileWithMe(payload);
  },
  ImageUploadAnonymous(params, payload, onUploadProgress) {
    return api.ImageUploadAnonymous(params, payload, onUploadProgress);
  },
  FileUploadAnonymous(params, payload, onUploadProgress) {
    return api.FileUploadAnonymous(params, payload, onUploadProgress);
  },
  FileDownloadAnonymous({ fileKey, fileName }, onDownloadProgress) {
    return api.FileDownloadAnonymous({ fileKey }, onDownloadProgress).then((response) => {
      saveByteArray(fileName, response.data, "application/octet-stream");
    });
  },
  SearchUser(params) {
    return api.SearchUser(params);
  },
  FetchSharedUsers(folderId) {
    return api.fetchSharedUsers(folderId);
  },
  CheckUser() {
    return api.checkUser();
  },
  GetMyStorageUsage() {
    return api.getMyStorageUsage();
  },
  RenameFolderAndFile(payload) {
    return api.renameFolderAndFile(payload);
  },
  PublicAvatarUpload(payload) {
    return api.publicAvatarUpload(payload);
  },
};
