import axios from "axios";
import ENUMS from "../../utils/enums";

import { toParam } from "../../utils/misc";
import config from "../config";

class Api {
  constructor() {
    this.api = axios.create({
      baseURL: config.baseURL,
    });
    this.CancelToken = axios.CancelToken;

    this.api.interceptors.request.use((config) => {
      const token = window.localStorage.getItem("keycloak-token");

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });
  }

  /**
   *
   * @param Query Params:
   * {int} folderId
   * {bool} isFileEncrypted
   * @param payload represents Request Body, contains the followings:
   * {IFormFile} formFile
   * @cancelOptions {Object}
   * @returns {Promise<AxiosResponse<any>>}
   */
  /* eslint-disable-next-line no-unused-vars */
  fileUpload(params, payload, onUploadProgress, cancelOptions) {
    return this.api.post(`${ENUMS.XPERDRIVE_API.FileUpload}?${toParam(params)}`, payload, {
      onUploadProgress,
      cancelToken: new this.CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancelOptions.cancel = c;
      }),
    });
  }

  /**
   *
   * @param payload represents Request Body, contains the followings:
   * {string} UserId
   * {int} FileIKey
   * @returns {Promise<AxiosResponse<any>>}
   */
  fileDownload(payload, onDownloadProgress) {
    const source = this.CancelToken.source();
    const promise = this.api.post(ENUMS.XPERDRIVE_API.FileDownload, payload, {
      responseType: "blob",
      onDownloadProgress,
      cancelToken: source.token,
    });

    return {
      promise,
      source,
    };
  }

  /**
   *
   * @param payload represents Request Body, contains the followings:
   * {string} UserPassword
   * {List<int>} FiledIds
   * {List<Guid>} SharedUserIds
   * @returns {Promise<AxiosResponse<any>>}
   */
  fileShare(payload) {
    return this.api.post(ENUMS.XPERDRIVE_API.FileShare, payload);
  }

  /**
   *
   * @param payload represents Request Body, contains the followings:
   * {string} FileKey
   * {int} FileId
   * {List<String>} ParticipantsList
   * @returns {Promise<AxiosResponse<any>>}
   */
  publicFileShare(payload) {
    return this.api.post(ENUMS.XPERDRIVE_API.PublicShare, payload);
  }

  /**
   * @param {string} folderId
   * @returns {Promise<AxiosResponse<users>>}
   */
  fetchSharedUsers(folderId) {
    return this.api.get(`${ENUMS.XPERDRIVE_API.FolderSharePermission}?folderId=${folderId}`);
  }

  /**
   * @param {string} folderId
   * @returns {Promise<AxiosResponse<string>>}
   */
  deleteFromSharedUsers(payload) {
    const { folderId, userIds } = payload;
    const userIdList = userIds.map((id) => `userId=${id}`).join("&");
    return this.api.delete(`${ENUMS.XPERDRIVE_API.FolderSharePermission}?folderId=${folderId}&${userIdList}`);
  }

  /**
   *
   * @param payload represents Request Body, contains the followings:
   * {string} FileName
   * {int} FolderId
   * @returns {Promise<AxiosResponse<any>>}
   */
  fileFolderSearch(query) {
    return this.api.get(`${ENUMS.XPERDRIVE_API.FileFolderSearch}?${toParam(query)}`);
  }

  /**
   * Creates folder with given FolderName param under givenFolderId
   * @param payload represents Request Body, contains the followings:
   * {string} FolderName
   * {int} ParentFolderId
   * @returns {Promise<AxiosResponse<any>>}
   */
  createFolder(payload) {
    return this.api.post(ENUMS.XPERDRIVE_API.CreateFolder, payload);
  }

  /**
   * Registers to xperdriver with given UserId and password
   * @param payload represents Request Body, contains the followings:
   * {Guid} UserId
   * {string} UserPassword
   * @returns {Promise<AxiosResponse<any>>}
   */
  register(payload) {
    return this.api.post(ENUMS.XPERDRIVE_API.Register, payload);
  }

  /**
   * Gets files that belongs to authenticated user
   * @returns {Promise<AxiosResponse<any>>}
   */
  getFiles(query) {
    return this.api.get(`${ENUMS.XPERDRIVE_API.GetFiles}?${toParam(query)}`);
  }

  /**
   * Stops sharing files with given fileId(s) with specified userId(s)
   * @param payload represents Request Body, contains the followings:
   * {List<int>} FileIds
   * {list<>} SharedUserIds
   * @returns {Promise<AxiosResponse<any>>}
   */
  stopFileSharing(payload) {
    return this.api.post(ENUMS.XPERDRIVE_API.StopFileSharing, payload);
  }

  /**
   * Deletes given fileId
   * @param payload represents Request Body, contains the followings:
   * {int} FileId
   * {bool} ForceDelete
   * @returns {Promise<AxiosResponse<any>>}
   */
  deleteFile(payload) {
    return this.api.delete(`${ENUMS.XPERDRIVE_API.DeleteFile}?${toParam(payload)}`);
  }

  /**
   * Shares folder with given folderId with specified userId(s)
   * @param payload represents Request Body, contains the followings:
   * {string} UserPassword
   * {int} FolderID
   * {List<Guid>} SharedUserIds
   * @returns {Promise<AxiosResponse<any>>}
   */
  folderShare(payload) {
    return this.api.post(ENUMS.XPERDRIVE_API.FolderShare, payload);
  }

  /**
   * Change shared user permissions for a folder.
   * @param payload represents Request Body, contains the followings:
   * {int} folderID
   * {List<Guid>} SharedUserList
   * @returns {Promise<AxiosResponse<any>>}
   */
  folderUpdatePermissions(payload) {
    return this.api.put(ENUMS.XPERDRIVE_API.FolderSharePermission, payload);
  }

  /**
   * Gets files from specified folderId
   * @param payload represents Request Body, contains the followings:
   * {int} folderId
   * @returns {Promise<AxiosResponse<any>>}
   */
  getFolderFiles(params, payload) {
    return this.api.get(ENUMS.XPERDRIVE_API.GetFolderFiles + `?${toParam(params)}`, payload);
  }

  /**
   * Gets shared filed list shared by current user
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSharedFiles() {
    return this.api.get(ENUMS.XPERDRIVE_API.GetSharedFiles);
  }

  /**
   * Gets shared filed list shared by current user
   * @returns {Promise<AxiosResponse<any>>}
   */
  getSharedFilesAndFolders({ folderId }) {
    return this.api.get(`${ENUMS.XPERDRIVE_API.GetSharedFilesAndFolder}?folderId=${folderId}`);
  }

  /**
   * Get user's files and folders
   * @returns {Promise<AxiosResponse<any>>}
   */
  getFoldersAndFiles({ folderId }) {
    return this.api.get(`${ENUMS.XPERDRIVE_API.GetFoldersAndFiles}?folderId=${folderId}`);
  }

  /**
   * Deletes given FolderId
   * @param payload represents Request Body, contains the followings:
   * {int} FolderId
   * {bool} ForceDelete
   * @returns {Promise<AxiosResponse<any>>}
   */
  deleteFolder(params) {
    return this.api.delete(`${ENUMS.XPERDRIVE_API.DeleteFolder}?${toParam(params)}`);
  }

  /**
   * Stops sharing folders with given folderId with specified SharedUserId
   * @param payload represents Request Body, contains the followings:
   * {int} FolderId
   * {Guid} SharedUserId
   * @returns {Promise<AxiosResponse<any>>}
   */
  stopFolderSharing(payload) {
    return this.api.post(ENUMS.XPERDRIVE_API.StopFolderSharing, payload);
  }

  /**
   * Gets folders that belongs to authenticated user
   * @returns {Promise<AxiosResponse<any>>}
   */
  getFolders(params) {
    return this.api.get(ENUMS.XPERDRIVE_API.GetFolders + `?${toParam(params)}`);
  }

  /**
   * Moves file from source to destination folder
   * @param payload represents Request Body, contains the followings:
   * {int} SourceId
   * {list<int>} SourceContents
   * {int} DestinationId
   * @returns {Promise<AxiosResponse<any>>}
   */
  moveFiles(payload) {
    return this.api.post(ENUMS.XPERDRIVE_API.MoveFiles, payload);
  }

  /**
   * Gets registered user list
   * @returns {Promise<AxiosResponse<any>>}
   */
  getUsers() {
    return this.api.get(ENUMS.XPERDRIVE_API.GetUsers);
  }

  /**
   * Checks is current user registered
   * @returns {Promise<AxiosResponse<any>>}
   */
  isRegisteredUser() {
    return this.api.get(ENUMS.XPERDRIVE_API.IsRegisteredUser);
  }

  /**
   * Retrieves the list of users previously shared files
   * @param payload represents Request Body, contains the followings:
   * {List<int>} fileIds
   * @returns {Promise<AxiosResponse<any>>}
   */
  getFileSharedUsers(payload) {
    return this.api.post(ENUMS.XPERDRIVE_API.GetFileSharedUsers, payload);
  }

  /**
   * Stops file sharing with current user
   * @param payload represents Request Body, contains the followings:
   * {int} FileId
   * @returns {Promise<AxiosResponse<any>>}
   */
  stopFileSharingFileWithMe(payload) {
    return this.api.post(ENUMS.XPERDRIVE_API.StopSharingFileWithMe, payload);
  }

  /**
   * Uploads file to xperdrive without user information
   * @param Query Params:
   * {int} folderId
   * {bool} isFileEncrypted
   * @param payload represents Request Body, contains the followings:
   * {IFormFile} formFile
   * @returns {Promise<AxiosResponse<any>>}
   */
  FileUploadAnonymous(params, payload, onUploadProgress) {
    const source = this.CancelToken.source();

    const promise = this.api.post(`${ENUMS.XPERDRIVE_API.FileUploadAnonymous}?${toParam(params)}`, payload, {
      onUploadProgress,
      cancelToken: source.token,
    });

    return {
      promise,
      source,
    };
  }

  ImageUploadAnonymous(params, payload, onUploadProgress) {
    const source = this.CancelToken.source();

    let url = `${ENUMS.XPERDRIVE_API.ImageUploadAnonymous}`;
    if (params) {
      url += `?${toParam(params)}`;
    }
    const promise = this.api.post(url, payload, {
      onUploadProgress,
      cancelToken: source.token,
    });

    return {
      promise,
      source,
    };
  }

  FileDownloadAnonymous(fileKey, onDownloadProgress) {
    return this.api.get(ENUMS.XPERDRIVE_API.FileDownloadAnonymous + `?${toParam(fileKey)}`, {
      onDownloadProgress,
      responseType: "blob",
    });
  }

  /**
   * Search User with text param
   * @param params
   * @return {Promise<AxiosResponse<any>>}
   * @constructor
   */
  SearchUser(params) {
    return this.api.get(ENUMS.XPERDRIVE_API.SearchUser + `?${toParam(params)}`);
  }

  /**
   * Send a get request when user logged in
   * @return {Promise<AxiosResponse<any>>}
   */
  checkUser() {
    return this.api.get(ENUMS.XPERDRIVE_API.CheckUser);
  }

  /**
   * Get user storage information
   * @return {Promise<AxiosResponse<any>>}
   */
  getMyStorageUsage() {
    return this.api.post(ENUMS.XPERDRIVE_API.GetMyStorageUsage);
  }

  /**
   * Rename file or folder
   * @param payload represents Request Body, contains the followings:
   * {string} newName
   * {int} id
   * {boolean} isFodler
   * @return {Promise<AxiosResponse<any>>}
   */
  renameFolderAndFile(payload) {
    return this.api.post(ENUMS.XPERDRIVE_API.RenameFolderAndFile, payload);
  }

  publicAvatarUpload(payload) {
    return this.api.post(ENUMS.XPERDRIVE_API.PublicAvatarUpload, payload);
  }
}

export default new Api();
